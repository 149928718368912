<template>
	<div class="out" ref="out"  >
     <!-- <van-pull-refresh v-model="isLoading" @refresh="loadDoodState"> -->
       <div class="header">
          <div class="logo Title">
            <div class="Chinese"><img src="../assets/img/return_index.png" class="return1" alt=""
            @click="$router.back()" /> {{$t('main.mya')}}</div>
          </div>
          <div class="right-icon">
            <img src="../assets/img/logo-icon.png" alt="">
            <div class="cc-wc">
                {{$t('main.title')}}
            </div>
          </div>
		</div>
        <div class="body" v-if="list.length">
            <div class="detail-box" v-for="item in list" :key="item.artist_id" >
                <div class="detail-top" @click="goTcDetail(item)">
                  <div class="detail-left">
                    <div class="title">
                        <h3>{{item.name}}</h3>
                        <img v-if="item.type == 1" src="../assets/img/tc-author.png" alt="" />
                        <img  v-if="item.type == 2 && item.is_collector_inst==0" src="../assets/img/tc-Collector.png" alt="" />
                        <img  v-if="item.type == 2 && item.is_collector_inst==1" style="width:0.65rem;height:0.18rem;" src="../assets/img/scjg.png" alt="" />
                    </div>
                    <div class="honor-detail">
                        <div v-html="item.content.replace(/(\r\n|\n|\r)/gm, ' <br /> ')"></div>
                    </div>
                </div>
                <div class="detail-image">
                    <img v-lazy="(item.pic + '?width=216')" alt="" />
                </div>
                </div>
                <div class="last-items" v-if="item.lastGoods && item.lastGoods.length">
                    <div class="last-goods"  v-for="(items,index) in item.lastGoods" :key="index">
                       <div class="last-item-wrap" @click="goDetail(items)">
                         <img class="last-image"  v-lazy="(items.pictures[0] + '?width=165').replace(/((^[A-Za-z]{4,5})+\:)/, protocol)" alt="">
                         <img class="last-logo" v-if="index < 2" src="../assets/img/shangxin-logo.png" alt="">
                         <div class="last-item-title">
                            <div>{{items.name}}</div>
                         </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
		<div class="body"  v-if="!list.length">
			 <div class="empty_wrap">
                  <div class="empty">
                      <img src="../assets/img/no-data-tishi.png" alt="" />
                      {{$t('main.nftoM')}}
                  </div>
            </div>
		</div>
	  <!-- </van-pull-refresh> -->
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [],
				isLoading:false
			};
		},
		mounted() {
			this.protocol = location.protocol
			this.axios.get("/artist/getFollowList").then((res) => {
        if(this.$i18n.locale == 'en'){
          for(let i = 0; i < res.data.data.list.length;i++){
            res.data.data.list[i].content = res.data.data.list[i].content_intl? res.data.data.list[i].content_intl : res.data.data.list[i].content
            res.data.data.list[i].name = res.data.data.list[i].name_intl? res.data.data.list[i].name_intl : res.data.data.list[i].name
            if(res.data.data.list[i].lastGoods){
              for(let j = 0;j <res.data.data.list[i].lastGoods.length;j++){
                res.data.data.list[i].lastGoods[j].name = res.data.data.list[i].lastGoods[j].name_intl?res.data.data.list[i].lastGoods[j].name_intl:res.data.data.list[i].lastGoods[j].name
              }
            }
            
          }
        }
				
				this.list = res.data.data.list ?  res.data.data.list : [];
			});
		},
    
		methods: {
			// loadDoodState() {
			// 		this.isLoading = true;
			// 	this.axios.get("/artist/getFollowList").then((res) => {
			// 		this.isLoading = false;
      //               this.list = res.data.data.list;
      //           });
			// },
            goTcDetail(item){
                this.$router.push(`/tcdetail/${item.artist_id}`)
            },
            goDetail(item){
              this.$router.push(`/detail/${item.id}?status=1`)
            }
        }
	};
</script>

<style scoped>
.out {
  box-sizing: border-box;
  min-height: 100vh;
  height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
  background: url(../assets/img/index_bg.png) no-repeat center;
  background-size: 100% 100%;
}
.body {
  padding: 0.15rem;
  
  /* background-attachment: fixed; */
}
.header {
	padding: 0.05rem 0.15rem 0.09rem;
	display: flex;
	justify-content: space-between;
  box-sizing: border-box;
	/* line-height: 0; */
	align-items: center;
	background: #FFFFFF;
}

.Title {
	font-size: 0.16rem;
	position: relative;
}



.Chinese {
	color: #1b6a21;
	/* top: -0.2rem; */
	left: 0;
	position: relative;
	text-align: left;
	font-size: 0.18rem;
}

.cc-wc {
	font-size: 0.1rem;
	color: #e9630a;
}

.right-icon {
	/* width: 1rem; */
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.right-icon img {
	display: block;
	width: 100%;
	height: 100%;
	width: 0.65rem;
}
.empty {
  border-radius: 0.1rem;
  margin: 0.4rem auto;
  width: 3.205rem;
  color: #b28d80;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.16rem;
  text-align: center;
  padding-bottom: 0.29rem;
}
.empty img {
  margin-top: 0.84rem;
  margin-bottom: 0.30rem;
  width: 1.975rem;
  object-fit: contain;
  display: block;
}
.detail-box {
  background-image: url(../assets/img/tc-box-background.png);
  background-size: 100% 100%;
  border-radius: 0.1rem;
  padding: 0.15rem;
  margin-bottom: 0.15rem;
}
.detail-top{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.1rem;

}
.detail-left {
  width: 2.25rem;
}
.detail-left .title {
  font-size: 0.14rem;
  display: flex;
  flex-wrap: wrap;
}
.detail-left .title img {
  width: 0.51rem;
  height: 0.19rem;
  margin-left: 0.1rem;
  margin-top: 0.04rem;
}
.detail-image {
  width: 0.7rem;
  height: 1.2rem;
  overflow: hidden;
  margin-left: 0.18rem;
}
.detail-image img {
  width: 100%;
  height: 1.2rem;
  object-fit:contain;
}
.count {
  margin-top: 0.1rem;
  display: flex;
  flex-wrap: wrap;
}
.count .h3 {
  font-weight: 600;
  font-size: 0.15rem;
  color: #333;
}
.count .container {
  font-size: 0.15rem;
  color: #525252;
}
.brief-introduction .h3 {
  font-weight: 600;
  font-size: 0.15rem;
  color: #333;
}
.brief-introduction .container {
  font-size: 0.15rem;
  color: #525252;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.honor img {
  height: 0.18rem;
  width: 100%;
  object-fit: contain;
  margin: 0.03rem 0;
}
.honor-detail div {
  font-size: 0.15rem;
  color: #525252;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-top: 0.21rem;
}
.box-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0.1rem 0 0.05rem;
}
.box-footer img {
  height: 0.4rem;
}
.last-items{
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-top: 0.01rem solid #ccc;
    margin-top: 0.15rem;
    padding: 0.15rem 0;
    box-sizing: border-box;
}
.last-item-wrap{
    position: relative;
    width: 0.9rem;
    height: 1.2rem;
    border-radius: 0.05rem;
    overflow: hidden;
}
.last-goods .last-item-wrap .last-image{
    height: 1.2rem;
    width: 100%;
    object-fit: cover;
}
.last-goods .last-item-wrap .last-logo{
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.05rem;
    width: 0.33rem;
}
.last-goods .last-item-wrap .last-item-title{
    background: linear-gradient(rgba(0,0,0,0.2),black);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0.38rem;
    color: white;
    padding: 0.02rem;
    box-sizing: border-box;
    font-size: 0.12rem;
    display: flex;
}
.last-item-wrap .last-item-title div{
    align-self: center;
    
  overflow: hidden; 
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-flex: 2;
  -webkit-box-orient: vertical;
  line-height: 0.16rem;
  



  
}
</style>
